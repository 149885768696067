define(["/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js",
// require.toUrl
"./main", // to export dijit.BackgroundIframe
"dojo-proxy-loader?name=dojo/_base/config!/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domConstruct.create
"dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domStyle.set
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // lang.extend lang.hitch
"dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module" // has("ie"), has("trident"), has("quirks")
], function (dojoRequire, dijit, config, domConstruct, domStyle, lang, on, has) {
  // module:
  //		dijit/BackgroundIFrame

  // Flag for whether to create background iframe behind popups like Menus and Dialog.
  // A background iframe is useful to prevent problems with popups appearing behind applets/pdf files,
  // and is also useful on older versions of IE (IE6 and IE7) to prevent the "bleed through select" problem.
  // By default, it's enabled for IE6-10, excluding Windows Phone 8,
  // and it's also enabled for IE11 on Windows 7 and Windows 2008 Server.
  // TODO: For 2.0, make this false by default.  Also, possibly move definition to has.js so that this module can be
  // conditionally required via  dojo/has!bgIfame?dijit/BackgroundIframe
  has.add("config-bgIframe", has("ie") && !/IEMobile\/10\.0/.test(navigator.userAgent) ||
  // No iframe on WP8, to match 1.9 behavior
  has("trident") && /Windows NT 6.[01]/.test(navigator.userAgent));
  var _frames = new function () {
    // summary:
    //		cache of iframes

    var queue = [];
    this.pop = function () {
      var iframe;
      if (queue.length) {
        iframe = queue.pop();
        iframe.style.display = "";
      } else {
        // transparency needed for DialogUnderlay and for tooltips on IE (to see screen near connector)
        if (has("ie") < 9) {
          var burl = config["dojoBlankHtmlUrl"] || dojoRequire.toUrl("dojo/resources/blank.html") || "javascript:\"\"";
          var html = "<iframe src='" + burl + "' role='presentation'" + " style='position: absolute; left: 0px; top: 0px;" + "z-index: -1; filter:Alpha(Opacity=\"0\");'>";
          iframe = document.createElement(html);
        } else {
          iframe = domConstruct.create("iframe");
          iframe.src = 'javascript:""';
          iframe.className = "dijitBackgroundIframe";
          iframe.setAttribute("role", "presentation");
          domStyle.set(iframe, "opacity", 0.1);
        }
        iframe.tabIndex = -1; // Magic to prevent iframe from getting focus on tab keypress - as style didn't work.
      }
      return iframe;
    };
    this.push = function (iframe) {
      iframe.style.display = "none";
      queue.push(iframe);
    };
  }();
  dijit.BackgroundIframe = function (/*DomNode*/node) {
    // summary:
    //		For IE/FF z-index shenanigans. id attribute is required.
    //
    // description:
    //		new dijit.BackgroundIframe(node).
    //
    //		Makes a background iframe as a child of node, that fills
    //		area (and position) of node

    if (!node.id) {
      throw new Error("no id");
    }
    if (has("config-bgIframe")) {
      var iframe = this.iframe = _frames.pop();
      node.appendChild(iframe);
      if (has("ie") < 7 || has("quirks")) {
        this.resize(node);
        this._conn = on(node, 'resize', lang.hitch(this, "resize", node));
      } else {
        domStyle.set(iframe, {
          width: '100%',
          height: '100%'
        });
      }
    }
  };
  lang.extend(dijit.BackgroundIframe, {
    resize: function (node) {
      // summary:
      //		Resize the iframe so it's the same size as node.
      //		Needed on IE6 and IE/quirks because height:100% doesn't work right.
      if (this.iframe) {
        domStyle.set(this.iframe, {
          width: node.offsetWidth + 'px',
          height: node.offsetHeight + 'px'
        });
      }
    },
    destroy: function () {
      // summary:
      //		destroy the iframe
      if (this._conn) {
        this._conn.remove();
        this._conn = null;
      }
      if (this.iframe) {
        this.iframe.parentNode.removeChild(this.iframe);
        _frames.push(this.iframe);
        delete this.iframe;
      }
    }
  });
  return dijit.BackgroundIframe;
});