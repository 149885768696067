define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/6b8db688ff66dd979f200eb579090da6d1bedf76/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/page/request"], function (declare, request) {
  return declare([], {
    constructor: function (params) {
      document.addEventListener('click', function (e) {
        if (e.target.tagName !== 'A') {
          return;
        }
        request.get(params.endpoint + "&url=" + encodeURIComponent(e.target.href));
      }, false);
    }
  });
});